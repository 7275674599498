.sidebar-brand {
  background: $main-background-color;
  justify-content: flex-start;
  width: 80%;
  margin: 0 auto;

  &.bottom-line {
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      border-bottom: 1px solid #9BC49A;
    }
  }
}

.sidebar {
  background-color: $main-background-color;
}

.sidebar-nav {
  .nav-item, .nav-group {
    width: 87%;
    margin: 0 auto;

    &.bottom-line {
      padding-bottom: 10px;
      margin-bottom: 10px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        border-bottom: 1px solid #9BC49A;
      }
    }

    &.top-line {
      padding-top: 10px;
      margin-top: 10px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        border-bottom: 1px solid #9BC49A;
      }
    }
  }

  .nav-link {
    color: $nav-link-color;
    font-size: $sidebar-nav-link-size;

    margin: 8px auto;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    &.active {
      color: $nav-link-color;
      background: $nav-link-hover-color;
    }

    &:hover {
      color: $nav-link-color;
      background: $nav-link-hover-color;

      &.nav-group-toggle {
        &::after {
          background-image: url('../assets/icons/arrow_drop_down.svg');
        }
      }
    }
  }

  .nav-group.show {
    background: none;

    .nav-group-toggle {
      color: $nav-link-color;
    }
  }

  .nav-group-toggle {
    &::after {
      background-image: url('../assets/icons/arrow_drop_down.svg');

      &:hover {
        background-image: url('../assets/icons/arrow_drop_down.svg');
      }
    }
  }
}

html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: var(--cui-sidebar-nav-link-padding-x);
}

.btn-primary.selectUserDropdownToggle {
  gap: 5px;
  width: 80%;
  background: $nav-link-hover-color url('../assets/icons/arrow_drop_down.svg') no-repeat right 10px center;
  border: none;
  display: flex;
  padding: 6px;
  align-items: center;
  font-size: $sidebar-nav-link-size;

  &:hover {
    background: $nav-link-hover-color-alpha url('../assets/icons/arrow_drop_down.svg') no-repeat right 10px center;
  }
}

.selectUserDropdownList {
  padding: 5px 0;
  margin-bottom: 5px;
  background: $nav-link-hover-color
}

.selectUserDropdownItem {
  padding: 7px 5px;
  font-size: $sidebar-nav-link-size;
  background: $nav-link-hover-color;
  cursor: pointer;

  &:hover {
    background: $nav-link-hover-color-alpha;
  }

  &.active {
    background: $nav-link-color-active;
    color: $black;
  }
}
