.onboard-container {
    .swiper-pagination {
        text-align: left;
        padding-left: 40px;

        .swiper-pagination-bullet {
            transition: all linear .2s;
            background: #CCD1D8;
            width: 5px;
            height: 5px;
            border-radius: 2.5px;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: #fff;
                width: 15px;
            }
        }

        &.swiper-pagination-horizontal.swiper-pagination-bullets {
            bottom: 25px;
            
            .swiper-pagination-bullet {
                margin: 0 3px;
            }
        }
    }
}