// Onboarding styles
@import './components/swiper';

.onboard-container {
    background: linear-gradient(to right, $theme-onboard-background 0% 50%, $white 50% 100%);
    border-radius: 27px;
    padding: 27px 15px 27px 27px;

    .onboard-form-group {
        margin-top: 100px;
    }

    .card {
        &.custom {
            background: inherit;
            border: none;
            padding: 1.5rem 4rem;
        }
    }

    .login-header {
        color: $onboard-header-color;
        font-size: $onboard-header-size;

        span {
            color: $onboard-header-color-second;
        }
    }

    .login-sub-header {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-weight: 300;
        color: $onboard-subheader-color;
        font-size: $onboard-subheader-size;
    }

    .form-label {
        &.custom {
            color: $onboard-label-color;
            font-size: $onboard-label-size;
            font-weight: bold;
            margin-left: 5px;
        }
    }

    .btn {
        &.btn-no-style {
            background: none;
            box-shadow: none;
            color: inherit;

            &.custom {
            background: #fff;
            border-left: none;

                &:hover,
                &:focus,
                &:active {
                    background: #fff;
                }
            }

            &:hover,
            &:focus,
            &:active {
                background: none;
                box-shadow: none;
                color: inherit;
            }
        }
    }

    .forgot-register-links {
        font-weight: 600;
        color: $onboard-subheader-color;
        font-size: $onboard-forgot-registe-size;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;
        flex-direction: row;

        button {
            font-weight: 600;
            color: $onboard-subheader-color;
            font-size: $onboard-forgot-registe-size;
            text-decoration: none;
        }
    }

    .mr-1 {
        margin-right: 1rem;
    }

    .login-button {
        width: 100%;
        height: 38px;
        font-weight: 600;
        color: $onboard-subheader-color;
        font-size: $onboard-label-size;
        background: $onboard-button-background;
        border-color: $onboard-button-background;
    }

    .form-control, .input-group .btn {
        border: 1px solid $onboard-input-border-color;
    }

    .copyright {
        color: $onboard-footer-color;
        font-size: $onboard-label-size;
        font-weight: 600;
        position: absolute;
        bottom: 25px;
        left: 25px;
    }

    .slider-container {
        height: 784px;
        max-width: 611px;
        background: $onboard-slider-background;
        margin: 0 auto;
        padding: 0;

        .slide {
            display: flex;
            align-content: center;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            height: 100%;

            .slide-description {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            color: $onboard-slider-color;
            border-radius: 0 0 21px 21px;

            .slider-blur {
                backdrop-filter: blur(20.45px);
                padding: 40px 40px 60px 40px;
                border-radius: 0 0 21px 21px;
            }

            h2 {
                font-size: $onboard-header-size;
                font-weight: 600;
            }

            .slide-description-text {
                line-height: 1.5rem;
                font-size: $onboard-subheader-size;
                font-weight: 300;
            }
            }
        }
    }
}
