
@import './sidebar';

.wrapper {
  width       : 100%;
  will-change : auto;
  @include transition(padding .15s);
  border-radius: 30px;
  overflow: hidden;
  padding: 10px;
}

.sidebar-blank {
  min-width: var(--cui-sidebar-occupy-start)
}

.flex-wrapper {
  display: flex;
  padding: 10px 10px 10px 0;
  background: $main-background-color;
}

.bg-content {
  background-color: $content-background;
}

html:not([dir=rtl]) .breadcrumb-item + .breadcrumb-item::before {
  content: "";
  width: 24px;
  height: 24px;
  background: url('../assets/icons/arrow-right.svg') no-repeat left center;
}

.breadcrumb-item.active {
  color: $header-color-dark-gray;
}

.header-divider {
  border-color: white;
}

.header {
  border: none;
}

.header-nav {
  .nav-link {
    background: white;
    width: 45px;
    text-align: center;
    border-radius: 50%;
  }
}

h2 {
  font-weight: 400;
  font-size: 1rem;
  color: $header-color-dark-gray;
}


@media (min-width: 1900px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1620px;
  }
}