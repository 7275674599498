// Here you can add other styles
@import './variables';
@import './onboard';

.breadcrumb-item {
  & > a {
    color           : $theme-font-base;
    text-decoration : none;
  }
}

.sidebar-nav .nav-link {
  mix-blend-mode : hard-light;
}


@keyframes ppsFadeIn {
  from { opacity : 0 }
  to { opacity : 1 }
}

.pps-fade-in {
  animation : .5s ppsFadeIn ease-in-out;
}

.min-h-820 {
  min-height: 820px;
}

.header .container-fluid.header-top {
  min-height: 0;
}

a {
  text-decoration: none;
  color: #365F35;
  font-weight: 500;
}

.card {
  border: 1px solid #ECF4E7;
  border-radius: 20px;
  gap: 14.72px;
  padding: 24px;

  .card-header {
    border: none;
    background: #fff;
    color: #546176;
    font-weight: 500;
    font-size: 16px;
    line-height: 18.4px;
    letter-spacing: 0.18px;
    padding: var(--cui-card-cap-padding-y) 0;
  }

  .card-body {
    padding: 0;
    
    .counter {
      color: #1f1f1f;
      font-size: 24px;
      font-weight: 500;
    }

    .compare {
      color: #009875;
      font-size: 14px;

      span {
        color: #929CAB;
        font-size: 12px;
      }

      &.upIcon {
        padding-left: 15px;
        background: url('../assets/icons/arrow-up.svg') no-repeat left center;
      }
    }
  }

  &.chart {
    height: 100%;
    display: flex;

    .card-body {
      display: flex;
      flex-direction: column;
      
      .infoBlock {
        display: flex;
        align-items: center;
        height: 32px;
      }
    }
  }

  h5 {
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 15px;
  }

  .nav-tabs .nav-item .nav-link {
    color: #5C6B82;
    font-weight: 500;
    font-size: 16px;
  }

  .nav-tabs .nav-item .nav-link.active, .nav-tabs .nav-item .nav-link.active:hover {
    border-bottom: 3px solid #B9D8A5;
    background: none;
    border-radius: 0;
    color: #272D37;
    font-weight: 500;
    font-size: 16px;
  }

  .nav-tabs .nav-item .nav-link:hover {
    background: none;
    border-bottom: 3px solid #546176;
    border-radius: 0;
  }
}

.btn-primary {
  min-height: 40px;
  border-radius: 8px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background:#B9D8A5;
  color: #232323;
  border: none;

  &.vector {
    width: 25px;
    height: 25px;
    border-radius: 8px;
    padding: 10px;
    min-height: 0;
    background: #B9D8A5 url('../assets/icons/vector.svg') no-repeat center center;
  }
}

.shortcuts {
  .card {
    height: 100%;
  }
}

.template-element {
  border-bottom: 1px solid #EDEDED;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  padding-right: 14px;
  padding-bottom: 12px;
  padding-left: 14px;
  border-bottom-width: 1px;

  .leftElement {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .info {
    h2 {
      font-weight: 600;
      font-size: 12px;
      line-height: 14.52px;
      margin-bottom: 0;
    }

    .description {
      font-weight: 400;
      font-size: 10px;
      line-height: 12.1px;
    }
  }
}

.batch-element {
  border: 1px solid #E4EDF8;
  background-color:#F8FAFC;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px;

  .info {
    display: flex;
    justify-content: space-between;
  }

  .batch-left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      color: #262626;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0;
    }

    .description {
      line-height: 12px;
      font-size: 10px;
      color: #000;

    }

    .createTime {
      line-height: 16px;
      font-size: 8px;
      color: #5A5F67;
    }
  }

  .batch-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;

    .time-left {
      line-height: 16px;
      font-size: 8px;
      color: #5A5F67;
    }
  }

  a, .status {
    text-decoration: none;
    color: #365F35;
    font-weight: 500;
    font-size: 12px;
  }
}

.progress-container {
  display: flex;
  align-items: center;
  gap: 8px;

  .progress-bar {
    width: 100%; 
    height: 10px;
    background-color: #e5e5e5; 
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2); 
  
    .progress-fill {
      height: 100%;
      width: 65%; 
      background-color: #b7d7a8; 
      transition: width 0.5s ease-in-out;
      border-radius: 5px;
    }
  
    .progress-text {
      font-size: 8px;
      color: #5A5F67;
    }
    
  }
}

.create-batch {
  padding: 12px;
  text-align: center;
}

.plan-element {
  border: 1px solid #E4EDF8;
  background-color:#F8FAFC;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px;

  .info {
    display: flex;
    justify-content: space-between;
  }

  .plan-left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      color: #262626;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0;

      span {
        color: #5C6B82;
        font-size: 10px;
        font-weight: 500;
      }
    }

    .description {
      line-height: 12px;
      font-size: 10px;
      color: #929CAB;

    }
  }

  .plan-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
  }

  a {
    text-decoration: none;
    color: #5C6B82;
    font-weight: 500;
    font-size: 12px;
  }

  &.professional {
    background-color:#F8FBF8;
    border: none;

    .plan-left {  
      h2 {
        color: #365F35;
        line-height: 24px;
      }

      .description {
        line-height: 12px;
        font-size: 12px;
        color: #365F35;
      }

      .time {
        line-height: 10px;
        font-size: 8px;
        color: #000;
      }
    }
  }
}

.nav-tabs {
  display: flex;
  justify-content: flex-start;
  border: none;
  gap: 5px;

  .nav-item {
    flex: none;

    .nav-link {
      border-radius: 10px;
      font-size: 12px;
      color: #5C6B82;
      line-height: 14px;
      padding: 10px 16px;
      border: none;

      &:hover {
        background: #EEF6E8;
        border: none;
        color: #5C6B82;
      }

      &.active {
        background: #B9D8A5;
        border: none;
        color: #232323;
      }
    }
  }
}

.form-control, .form-select {
  border: 1px solid #CCD1D8;
  line-height: 22px;
  padding: 8px 0.75rem

}

textarea.form-control{
  min-height: 108px;
}

.form-select:invalid, .form-select::placeholder {
  color: #929CAB;
}

.form-label {
  color: #5C6B82;
  font-size: 14px;
  font-weight: 500;
}

button.drag-and-drop-input {
  width: 100%;
  background: #fff;
  border: 1px solid #CCD1D8;
  padding: 24px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  border-radius: 12px;

  .text {
    color: #929CAB;
    font-size: 14px;
    line-height: 24px;
    text-align: left;

    .upload-size {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  &:hover {
    background: #EEF6E8;
    border: 1px solid #CCD1D8;
  }
}

.btn.btn-add {
  background: #fff;
  color: #5c6b82;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  &:hover, &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.location-container {
  .btn {
    float: right;
  }

  &::after {
    display: table;
    clear: both;
    content: '';
  }
}

.btn.btn-secondary {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 16px;
  color: #5c6b82;
  background: #EFF0F3;
  border: none;
}

.btn.btn-success {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 16px;
  color: #232323;
  background: #B9D8A5;
  border: none;
}

.color-input, .form-control-color.form-control-sm.color-input {
  padding: 4px;
  width: 50px;
  height: 40px;
}

.color-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}

.color-info {
  font-size: 14px;
  font-weight: 400;
  color: #242524;
}

.color-picker-box {
  .form-label {
    font-size: 16px;
    font-weight: 500;
    color: #232323;
  }
}

.apple-generic-fields-container, .backfields-container {
  &::after {
    display: table;
    content: '';
    clear: both;
  }
}

.float-right {
  float: right;
}

a.greyAnchor {
  color: #5C6B82;
  text-decoration: underline;
  font-size: 12px;
  line-height: 20px;

  &:hover {
    color: #365F35;
  }
}

.table {
  thead {
    th {
      color: #232323;
      font-size: 14px;
      font-weight: 600;
      padding: 15px;
    }
  }

  tbody {
    td,th {
      color: #232323;
      font-size: 12px;
      font-weight: 400;
      padding: 22px 15px 12px 15px;
      vertical-align: middle;
      min-height: 28px;
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      button {
        width: 28px;
        height: 28px;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        min-height: 28px;
      }
    }
  }

  tr {
    border-color: #E2E8F0;
  }
}

hr {
  border-top: 1px solid #E2E8F0;
  opacity: 1;
}

h5 {
  button.btn.btn-primary {
    font-size: 12px ;
    font-weight: 500;
    color: #232323;
  }
}

.status-beam {
  width: 96px;
  line-height: 28px;
  border-radius: 14px;
  text-align: center;

  &.paid {
    background: #D0FFCF;
    color: #365F35;
    font-weight: 600;
    font-size: 12px;
  }

  &.pending {
    background: #FFE2E2;
    color: #CA0000;
    font-weight: 600;
    font-size: 12px;
  }
}

.table-wrapper {
  overflow-x: scroll;

  table {
    min-width: 700px;
  }
}

@media screen and (max-width: 1200px) {
    .onboard-container {
      .card.custom {
        padding: 1.5rem;
      }
    }

    .btn-primary {
      font-size: 12px;
      padding: 5px 5px;
      min-height: 30px;
    }
}

@media screen and (max-width: 991px) {
  .onboard-container {
    background: #F8FBF8;

    
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0;
  }
 
  div.preview-content {
    padding: 20px 15px 20px 0;
  }

  .card {
    padding: 24px 12px;
  }
}
